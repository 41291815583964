//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapGetters, mapActions } from 'vuex';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import CoachContentLabel from 'kolibri.coreVue.components.CoachContentLabel';
import DownloadButton from 'kolibri.coreVue.components.DownloadButton';
import { isEmbeddedWebView } from 'kolibri.utils.browserInfo';
import { shareFile } from 'kolibri.utils.appCapabilities';
import markdownIt from 'markdown-it';
import {
  licenseShortName,
  licenseLongName,
  licenseDescriptionForConsumer,
} from 'kolibri.utils.licenseTranslations';
import { PageNames, PageModes, ClassesPageNames } from '../constants';
import { updateContentNodeProgress } from '../modules/coreLearn/utils';
import PageHeader from './PageHeader';
import ContentCardGroupCarousel from './ContentCardGroupCarousel';
import AssessmentWrapper from './AssessmentWrapper';
import MasteredSnackbars from './MasteredSnackbars';
import { lessonResourceViewerLink } from './classes/classPageLinks';
import commonLearnStrings from './commonLearnStrings';

export default {
  name: 'ContentPage',
  metaInfo() {
    // Do not overwrite metaInfo of LessonResourceViewer
    if (this.pageName === ClassesPageNames.LESSON_RESOURCE_VIEWER) {
      return {};
    }
    return {
      title: this.$tr('documentTitle', {
        contentTitle: this.content.title,
        channelTitle: this.channel.title,
      }),
    };
  },
  components: {
    CoachContentLabel,
    PageHeader,
    ContentCardGroupCarousel,
    DownloadButton,
    AssessmentWrapper,
    MasteredSnackbars,
  },
  mixins: [commonLearnStrings],
  data() {
    return {
      wasIncomplete: false,
      licenceDescriptionIsVisible: false,
      sessionReady: false,
    };
  },
  computed: {
    ...mapGetters(['isUserLoggedIn', 'facilityConfig', 'pageMode', 'currentUserId']),
    ...mapState(['pageName']),
    ...mapState('topicsTree', ['content', 'channel', 'recommended']),
    ...mapState('topicsTree', {
      contentId: state => state.content.content_id,
      contentNodeId: state => state.content.id,
      channelId: state => state.content.channel_id,
    }),
    ...mapState({
      masteryAttempts: state => state.core.logging.mastery.totalattempts,
      summaryProgress: state => state.core.logging.summary.progress,
      summaryTimeSpent: state => state.core.logging.summary.time_spent,
      sessionProgress: state => state.core.logging.session.progress,
      extraFields: state => state.core.logging.summary.extra_fields,
      fullName: state => state.core.session.full_name,
    }),
    isTopic() {
      return this.content.kind === ContentNodeKinds.TOPIC;
    },
    canDownload() {
      if (this.facilityConfig.show_download_button_in_learn && this.content) {
        return (
          this.downloadableFiles.length &&
          this.content.kind !== ContentNodeKinds.EXERCISE &&
          !isEmbeddedWebView
        );
      }
      return false;
    },
    canShare() {
      let supported_types = ['mp4', 'mp3', 'pdf', 'epub'];
      return shareFile && supported_types.includes(this.primaryFile.extension);
    },
    description() {
      if (this.content && this.content.description) {
        const md = new markdownIt('zero', { breaks: true });
        return md.render(this.content.description);
      }
      return '';
    },
    recommendedText() {
      return this.learnString('recommendedLabel');
    },
    progress() {
      if (this.isUserLoggedIn) {
        // if there no attempts for this exercise, there is no progress
        if (this.content.kind === ContentNodeKinds.EXERCISE && this.masteryAttempts === 0) {
          return undefined;
        }
        return this.summaryProgress;
      }
      return this.sessionProgress;
    },
    showRecommended() {
      return (
        this.recommended && this.recommended.length && this.pageMode === PageModes.RECOMMENDED
      );
    },
    downloadableFiles() {
      return this.content.files.filter(file => !file.preset.endsWith('thumbnail'));
    },
    primaryFile() {
      return this.content.files.filter(file => !file.preset.supplementary)[0];
    },
    primaryFilename() {
      return `${this.primaryFile.checksum}.${this.primaryFile.extension}`;
    },
    nextContentLink() {
      // HACK Use a the Resource Viewer Link instead
      if (this.pageName === ClassesPageNames.LESSON_RESOURCE_VIEWER) {
        return lessonResourceViewerLink(Number(this.$route.params.resourceNumber) + 1);
      }
      return {
        name:
          this.content.next_content.kind === ContentNodeKinds.TOPIC
            ? PageNames.TOPICS_TOPIC
            : PageNames.TOPICS_CONTENT,
        params: { id: this.content.next_content.id },
      };
    },
    licenseShortName() {
      return licenseShortName(this.content.license_name);
    },
    licenseLongName() {
      return licenseLongName(this.content.license_name);
    },
    licenseDescription() {
      return licenseDescriptionForConsumer(
        this.content.license_name,
        this.content.license_description
      );
    },
  },
  created() {
    return this.initSessionAction({
      channelId: this.channelId,
      contentId: this.contentId,
      contentKind: this.content.kind,
    }).then(() => {
      this.sessionReady = true;
      this.setWasIncomplete();
    });
  },
  beforeDestroy() {
    this.stopTracking();
  },
  methods: {
    ...mapActions({
      initSessionAction: 'initContentSession',
      updateProgressAction: 'updateProgress',
      addProgressAction: 'addProgress',
      startTracking: 'startTrackingProgress',
      stopTracking: 'stopTrackingProgress',
      updateContentNodeState: 'updateContentState',
    }),
    setWasIncomplete() {
      this.wasIncomplete = this.progress < 1;
    },
    updateProgress(progressPercent, forceSave = false) {
      this.updateProgressAction({ progressPercent, forceSave }).then(updatedProgressPercent =>
        updateContentNodeProgress(this.channelId, this.contentNodeId, updatedProgressPercent)
      );
      this.$emit('updateProgress', progressPercent);
    },
    addProgress(progressPercent, forceSave = false) {
      this.addProgressAction({ progressPercent, forceSave }).then(updatedProgressPercent =>
        updateContentNodeProgress(this.channelId, this.contentNodeId, updatedProgressPercent)
      );
      this.$emit('addProgress', progressPercent);
    },
    updateExerciseProgress(progressPercent) {
      this.$emit('updateProgress', progressPercent);
    },
    updateContentState(contentState, forceSave = true) {
      this.updateContentNodeState({ contentState, forceSave });
    },
    markAsComplete() {
      this.wasIncomplete = false;
    },
    genContentLink(id, kind) {
      return {
        name: kind === ContentNodeKinds.TOPIC ? PageNames.TOPICS_TOPIC : PageNames.TOPICS_CONTENT,
        params: { id },
      };
    },
    launchIntent() {
      return shareFile({
        filename: this.primaryFilename,
        message: this.$tr('shareMessage', {
          title: this.content.title,
          topic: this.content.breadcrumbs.slice(-1)[0].title,
          copyrightHolder: this.content.license_owner,
        }),
      }).catch(() => {});
    },
  },
  $trs: {
    author: 'Author: {author}',
    license: 'License: {license}',
    toggleLicenseDescription: 'Toggle license description',
    copyrightHolder: 'Copyright holder: {copyrightHolder}',
    shareMessage: '"{title}" (in "{topic}"), from {copyrightHolder}',
    nextResource: 'Next resource',
    documentTitle: '{ contentTitle } - { channelTitle }',
    shareFile: 'Share',
  },
};

