//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import { validateLinkObject, validateContentNodeKind } from 'kolibri.utils.validators';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import CoachContentLabel from 'kolibri.coreVue.components.CoachContentLabel';
import TextTruncator from 'kolibri.coreVue.components.TextTruncator';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import CardThumbnail from './ContentCard/CardThumbnail';

export default {
  name: 'ChannelCard',
  components: {
    CardThumbnail,
    CoachContentLabel,
    TextTruncator,
    ProgressIcon,
  },
  mixins: [responsiveWindowMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    tagline: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      required: false,
    },
    kind: {
      type: String,
      required: true,
      validator: validateContentNodeKind,
    },
    // ContentNode.coach_content will be `0` if not a coach content leaf node,
    // or a topic without coach content. It will be a positive integer if a topic
    // with coach content, and `1` if a coach content leaf node.
    numCoachContents: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      required: false,
      default: 0.0,
      validator(value) {
        return value >= 0.0 && value <= 1.0;
      },
    },
    link: {
      type: Object,
      required: true,
      validator: validateLinkObject,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isLearner', 'isUserLoggedIn']),
    isTopic() {
      return this.kind === ContentNodeKinds.TOPIC || this.kind === ContentNodeKinds.CHANNEL;
    },
    overallHeight() {
      return 258;
    },
    cardStyle() {
      return {
        backgroundColor: this.$themeTokens.surface,
        color: this.$themeTokens.text,
        marginBottom: `${this.windowGutter}px`,
        minHeight: `${this.overallHeight}px`,
      };
    },
    taglineHeight() {
      return 165;
    },
  },
};

