//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'ContentCardGroupHeader',
  mixins: [commonCoreStrings],
  props: {
    header: { type: String },
    viewMorePageLink: { type: Object },
    showViewMore: { type: Boolean },
  },
  $trs: {},
};

