//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import maxBy from 'lodash/maxBy';
import { mapGetters, mapState } from 'vuex';
import { ContentNodeKinds } from 'kolibri.coreVue.vuex.constants';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import responsiveElementMixin from 'kolibri.coreVue.mixins.responsiveElementMixin';
import { PageNames } from '../constants';

const ALL_FILTER = null;

const kindFilterToLabelMap = {
  [ContentNodeKinds.TOPIC]: 'topics',
  [ContentNodeKinds.EXERCISE]: 'exercises',
  [ContentNodeKinds.VIDEO]: 'videos',
  [ContentNodeKinds.AUDIO]: 'audio',
  [ContentNodeKinds.DOCUMENT]: 'documents',
  [ContentNodeKinds.HTML5]: 'html5',
};

export default {
  name: 'SearchBox',
  mixins: [commonCoreStrings, responsiveElementMixin],
  props: {
    icon: {
      type: String,
      default: 'search',
      validator(val) {
        return ['search', 'forward'].includes(val);
      },
    },
    filters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: this.$store.state.search.searchTerm,
      contentKindFilterSelection: {},
      channelFilterSelection: {},
    };
  },
  computed: {
    ...mapGetters({
      channels: 'getChannels',
    }),
    ...mapState('search', [
      'searchTerm',
      'channel_ids',
      'content_kinds',
      'kindFilter',
      'channelFilter',
    ]),
    channelFilterStyle() {
      const maxWidth = 375;
      // If window is small, just let it have its default width
      if (this.elementWidth < maxWidth + 32) {
        return {};
      }
      // Otherwise, adjust the width based on the longest channel name,
      // capped at 375px, or approx 50 characters
      const longestChannelName = maxBy(
        this.channelFilterOptions,
        channel => channel.label.length
      );
      const maxPx = Math.min(longestChannelName.label.length * 8, maxWidth);
      return {
        width: `${maxPx}px`,
      };
    },
    allFilter() {
      return { label: this.coreString('allLabel'), value: ALL_FILTER };
    },
    contentKindFilterOptions() {
      if (this.content_kinds.length) {
        const options = Object.keys(kindFilterToLabelMap)
          .filter(kind => this.content_kinds.includes(kind))
          .map(kind => ({
            label: this.$tr(kindFilterToLabelMap[kind]),
            value: kind,
          }));
        return [this.allFilter, ...options];
      }
      return [];
    },
    channelFilterOptions() {
      if (this.channel_ids.length) {
        const options = this.channels
          .filter(channel => this.channel_ids.includes(channel.id))
          .map(channel => ({
            label: channel.title,
            value: channel.id,
          }));
        return [this.allFilter, ...options];
      }
      return [];
    },
    filterUpdate() {
      return (
        this.contentKindFilterSelection.value !== this.kindFilter ||
        this.channelFilterSelection.value !== this.channelFilter
      );
    },
    searchUpdate() {
      return this.searchQuery !== this.searchTerm || this.filterUpdate;
    },
    searchInputStyle() {
      return {
        '::placeholder': {
          color: this.$themeTokens.annotation,
        },
        color: this.$themeTokens.text,
      };
    },
  },
  watch: {
    searchTerm(val) {
      this.searchQuery = val || '';
    },
  },
  beforeMount() {
    this.contentKindFilterSelection =
      this.contentKindFilterOptions.find(
        option => option.value === this.$store.state.search.kindFilter
      ) || this.allFilter;
    this.channelFilterSelection =
      this.channelFilterOptions.find(
        option => option.value === this.$store.state.search.channelFilter
      ) || this.allFilter;
  },
  methods: {
    handleEscKey() {
      if (this.searchQuery === '') {
        this.$emit('closeDropdownSearchBox');
      } else {
        this.searchQuery = '';
      }
    },
    handleClickClear() {
      this.searchQuery = '';
      this.$refs.searchInput.focus();
    },
    updateFilter() {
      this.search(true);
    },
    search(filterUpdate = false) {
      if (this.searchQuery !== '') {
        const query = {
          searchTerm: this.searchQuery,
        };
        if (filterUpdate === true) {
          if (this.$refs.contentKindFilter.selection.value) {
            query.kind = this.$refs.contentKindFilter.selection.value;
          }
          if (this.$refs.channelFilter.selection.value) {
            query.channel_id = this.$refs.channelFilter.selection.value;
          }
        }
        this.$router.push({
          name: PageNames.SEARCH,
          query,
        });
      }
    },
  },
  $trs: {
    clearButtonLabel: 'Clear',
    startSearchButtonLabel: 'Start search',
    resourceType: 'Type',
    topics: 'Topics',
    exercises: 'Exercises',
    videos: 'Videos',
    audio: 'Audio',
    documents: 'Documents',
    html5: 'Apps',
  },
};

